import { Select } from "antd";
import usePerfilesCircuitos from "../../hooks/perfiles/usePerfilesCircuitos";

const { Option } = Select;

const PerfilesCircuitos = (props) => {
  console.log("PerfilesCircuitos props: ", props);
  const { PerfilesCircuitos, isLoadingPerfilesCircuitos } =
    usePerfilesCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.algoritmo,
      props.perfil
    );

  const handleChangePerfilesCircuitos = (value) => {
    console.log(`PerfilesCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Perfiles"
      value={props.perfil}
      loading={isLoadingPerfilesCircuitos}
      onChange={handleChangePerfilesCircuitos}
      allowClear
    >
      <Option value="" />
      {PerfilesCircuitos.map((perfil) => (
        <Option key={perfil.perfil} value={perfil.perfil}>
          {perfil.nom_perfil}
        </Option>
      ))}
    </Select>
  );
};

export default PerfilesCircuitos;
