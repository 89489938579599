import { Select } from "antd";
import useCategoriasSecciones from "../../hooks/perfiles/useCategoriasSecciones";

const { Option } = Select;

const CategoriasSecciones = (props) => {
  console.log("CategoriasSecciones props: ", props);
  const { categoriasSecciones, isLoadingCategoriasSecciones } =
    useCategoriasSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.algortimo,
      props.perfil
    );

  const handleChangeCategoriasSecciones = (value) => {
    console.log(`CategoriasSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Categorías"
      value={props.categoria}
      loading={isLoadingCategoriasSecciones}
      onChange={handleChangeCategoriasSecciones}
      allowClear
    >
      <Option value="" />
      {categoriasSecciones.map((categoria) => (
        <Option key={categoria.cod_categoria} value={categoria.cod_categoria}>
          {categoria.nom_categoria}
        </Option>
      ))}
    </Select>
  );
};

export default CategoriasSecciones;
