import { Select } from "antd";
import useEleccionesCircuitos from "../../hooks/perfiles/useEleccionesCircuitos";

const { Option } = Select;

const EleccionesCircuitos = (props) => {
  console.log("EleccionesCircuitos props: ", props);
  const { EleccionesCircuitos, isLoadingEleccionesCircuitos } =
    useEleccionesCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.algoritmo,
      props.perfil
    );

  const handleChangeEleccionesCircuitos = (value) => {
    console.log(`EleccionesCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Elecciones"
      value={props.eleccion}
      loading={isLoadingEleccionesCircuitos}
      onChange={handleChangeEleccionesCircuitos}
      allowClear
    >
      <Option value="" />
      {EleccionesCircuitos.map((eleccion) => (
        <Option key={eleccion.cod_eleccion} value={eleccion.cod_eleccion}>
          {eleccion.nom_eleccion}
        </Option>
      ))}
    </Select>
  );
};

export default EleccionesCircuitos;
