import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const usePerfilesCircuitos = (
  eleccion: string,
  categoria: string,
  distrito: string,
  seccion: string,
  circuito: string,
  algoritmo: string,
  perfil: string
) => {
  const [isLoading, setLoading] = useState(true);
  const [PerfilesCircuitos, setPerfilesCircuitos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          categoria: categoria,
          distrito: distrito,
          seccion: seccion,
          circuito: circuito,
          algoritmo: algoritmo,
          perfil: perfil,
        };
        const res = await fetch(
          `${
            AppConfig.API_URL
          }/perfiles/perfilesCircuitosPerfiles?${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setPerfilesCircuitos(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, categoria, distrito, seccion, circuito, algoritmo, perfil]);

  return { isLoadingPerfilesCircuitos: isLoading, PerfilesCircuitos };
};
export default usePerfilesCircuitos;
