import { Select } from "antd";
import useAlgoritmosSecciones from "../../hooks/perfiles/useAlgoritmosSecciones";

const { Option } = Select;

const AlgoritmosSecciones = (props) => {
  console.log("AlgoritmosSecciones props: ", props);
  const { algoritmosSecciones, isLoadingAlgoritmosSecciones } =
    useAlgoritmosSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.algoritmo,
      props.perfil
    );

  const handleChangeAlgoritmosSecciones = (value) => {
    console.log(`AlgoritmosSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Algoritmos"
      value={props.algoritmo}
      loading={isLoadingAlgoritmosSecciones}
      onChange={handleChangeAlgoritmosSecciones}
      allowClear
    >
      <Option value="" />
      {algoritmosSecciones.map((algoritmo) => (
        <Option key={algoritmo.cod_algoritmo} value={algoritmo.cod_algoritmo}>
          {algoritmo.nom_algoritmo}
        </Option>
      ))}
    </Select>
  );
};

export default AlgoritmosSecciones;
