import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useResultadosPerfilesCircuitos = ({
  eleccion,
  categoria,
  distrito,
  seccion,
  circuito,
  algoritmo,
  perfil,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [resultados, setResultados] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        if (!eleccion && !categoria) {
          return [];
        }
        const params = {
          eleccion: eleccion,
          categoria: categoria,
          distrito: distrito,
          seccion: seccion,
          circuito: circuito,
          algoritmo: algoritmo,
          perfil: perfil,
        };
        const res = await fetch(
          `${
            AppConfig.API_URL
          }/perfiles/perfilesCircuitos?${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setResultados(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, categoria, distrito, seccion, circuito, algoritmo, perfil]);

  return [resultados, isLoading];
};
export default useResultadosPerfilesCircuitos;
