import { Select } from "antd";
import useSeccionesSecciones from "../../hooks/perfiles/useSeccionesSecciones";

const { Option } = Select;

const SeccionesSecciones = (props) => {
  console.log("SeccionesSecciones props: ", props);
  const { seccionesSecciones, isLoadingSeccionesSecciones } =
    useSeccionesSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.algortimo,
      props.perfil
    );

  const handleChangeSeccionesSecciones = (value) => {
    console.log(`SeccionesSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Secciones"
      value={props.seccion}
      loading={isLoadingSeccionesSecciones}
      onChange={handleChangeSeccionesSecciones}
      allowClear
    >
      <Option value="" />
      {seccionesSecciones.map((seccion) => (
        <Option key={seccion.cod_seccion} value={seccion.cod_seccion}>
          {seccion.nom_seccion}
        </Option>
      ))}
    </Select>
  );
};

export default SeccionesSecciones;
