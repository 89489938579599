import { useState } from "react";
import EleccionesSecciones from "./components/perfiles/EleccionesSecciones";
import CategoriasSecciones from "./components/perfiles/CategoriasSecciones";
import DistritosSecciones from "./components/perfiles/DistritosSecciones";
import SeccionesSecciones from "./components/perfiles/SeccionesSecciones";
import AlgoritmosSecciones from "./components/perfiles/AlgoritmosSecciones";
import PerfilesSecciones from "./components/perfiles/PerfilesSecciones";
import ListaResultadosPerfilesSecciones from "./components/perfiles/ListaResultadosPerfilesSecciones";

import EleccionesCircuitos from "./components/perfiles/EleccionesCircuitos";
import CategoriasCircuitos from "./components/perfiles/CategoriasCircuitos";
import DistritosCircuitos from "./components/perfiles/DistritosCircuitos";
import SeccionesCircuitos from "./components/perfiles/SeccionesCircuitos";
import CircuitosCircuitos from "./components/perfiles/CircuitosCircuitos";
import AlgoritmosCircuitos from "./components/perfiles/AlgoritmosCircuitos";
import PerfilesCircuitos from "./components/perfiles/PerfilesCircuitos";
import ListaResultadosPerfilesCircuitos from "./components/perfiles/ListaResultadosPerfilesCircuitos";

export default function PerfilesApp() {
  const [eleccion, setEleccion] = useState();
  const [categoria, setCategoria] = useState();
  const [distrito, setDistrito] = useState();
  const [seccion, setSeccion] = useState();
  const [algoritmo, setAlgoritmo] = useState();
  const [perfil, setPerfil] = useState();

  const [porCircuito, setPorCircuito] = useState(false);

  const [eleccionCircuito, setEleccionCircuito] = useState();
  const [categoriaCircuito, setCategoriaCircuito] = useState();
  const [distritoCircuito, setDistritoCircuito] = useState();
  const [seccionCircuito, setSeccionCircuito] = useState();
  const [circuitoCircuito, setCircuitoCircuito] = useState();
  const [algoritmoCircuito, setAlgoritmoCircuito] = useState();
  const [perfilCircuito, setPerfilCircuito] = useState();

  return (
    <div className="container">
      <div style={{ marginTop: 30, marginLeft: 20 }}>
        {porCircuito ? (
          <>
            <EleccionesCircuitos
              onChange={setEleccionCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              algoritmo={algoritmoCircuito}
              perfil={perfilCircuito}
            />
            <CategoriasCircuitos
              onChange={setCategoriaCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              algoritmo={algoritmoCircuito}
            />
            <DistritosCircuitos
              onChange={setDistritoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              algoritmo={algoritmoCircuito}
            />
            <SeccionesCircuitos
              onChange={setSeccionCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              algoritmo={algoritmoCircuito}
            />
            <CircuitosCircuitos
              onChange={setCircuitoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              circuito={circuitoCircuito}
              algoritmo={algoritmoCircuito}
            />
            <AlgoritmosCircuitos
              onChange={setCircuitoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              circuito={circuitoCircuito}
              algoritmo={algoritmoCircuito}
              perfil={perfilCircuito}
            />
            <PerfilesCircuitos
              onChange={setPerfilCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              circuito={circuitoCircuito}
              algoritmo={algoritmoCircuito}
              perfil={perfilCircuito}
            />
            <label
              style={{
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <input
                type="checkbox"
                checked={porCircuito}
                onChange={(e) => setPorCircuito(e.target.checked)}
                style={{ marginRight: 8 }}
              />
              Por Circuito
            </label>
            <ListaResultadosPerfilesCircuitos
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              perfil={perfilCircuito}
            />
          </>
        ) : (
          <>
            <EleccionesSecciones
              onChange={setEleccion}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              algoritmo={algoritmo}
              perfil={perfil}
            />
            <CategoriasSecciones
              onChange={setCategoria}
              eleccion={eleccion}
              categoria={categoria}
              algoritmo={algoritmo}
            />
            <DistritosSecciones
              onChange={setDistrito}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              algoritmo={algoritmo}
            />
            <SeccionesSecciones
              onChange={setSeccion}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              algoritmo={algoritmo}
            />
            <AlgoritmosSecciones
              onChange={setAlgoritmo}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              algoritmo={algoritmo}
              perfil={perfil}
            />
            <PerfilesSecciones
              onChange={setPerfil}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              algoritmo={algoritmo}
              perfil={perfil}
            />
            <label
              style={{
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <input
                type="checkbox"
                checked={porCircuito}
                onChange={(e) => setPorCircuito(e.target.checked)}
                style={{ marginRight: 8 }}
              />
              Por Circuito
            </label>
            <ListaResultadosPerfilesSecciones
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              algoritmo={algoritmo}
              perfil={perfil}
            />
          </>
        )}
      </div>
    </div>
  );
}
