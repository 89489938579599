import { Select } from "antd";
import usePerfilesSecciones from "../../hooks/perfiles/usePerfilesSecciones";

const { Option } = Select;

const PerfilesSecciones = (props) => {
  console.log("PerfilesSecciones props: ", props);
  const { perfilesSecciones, isLoadingPerfilesSecciones } =
    usePerfilesSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.algortimo,
      props.perfil
    );

  const handleChangePerfilesSecciones = (value) => {
    console.log(`PerfilesSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Perfiles"
      value={props.perfil}
      loading={isLoadingPerfilesSecciones}
      onChange={handleChangePerfilesSecciones}
      allowClear
    >
      <Option value="" />
      {perfilesSecciones.map((perfil) => (
        <Option key={perfil.perfil} value={perfil.perfil}>
          {perfil.nom_perfil}
        </Option>
      ))}
    </Select>
  );
};

export default PerfilesSecciones;
