import { Select } from "antd";
import useCategoriasCircuitos from "../../hooks/perfiles/useCategoriasCircuitos";

const { Option } = Select;

const CategoriasCircuitos = (props) => {
  console.log("CategoriasCircuitos props: ", props);
  const { CategoriasCircuitos, isLoadingCategoriasCircuitos } =
    useCategoriasCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.algoritmo,
      props.perfil
    );

  const handleChangeCategoriasCircuitos = (value) => {
    console.log(`CategoriasCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Categorías"
      value={props.categoria}
      loading={isLoadingCategoriasCircuitos}
      onChange={handleChangeCategoriasCircuitos}
      allowClear
    >
      <Option value="" />
      {CategoriasCircuitos.map((categoria) => (
        <Option key={categoria.cod_categoria} value={categoria.cod_categoria}>
          {categoria.nom_categoria}
        </Option>
      ))}
    </Select>
  );
};

export default CategoriasCircuitos;
